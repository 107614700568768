import PropTypes from 'prop-types';
import * as React from 'react';
// import { MDXRenderer } from "gatsby-plugin-mdx";
import { graphql } from 'gatsby';
import { ChevronRightIcon } from '@bgea-js/react-icons';
import { HeaderPage } from '@bgea-js/design-library';
import { SingleDonation } from '@bgea-js/form-templates';
import Layout from '../components/Layout';
import * as Ui from '@bgea-js/react-ui';
import { StaticImage } from 'gatsby-plugin-image';
import { Seo } from '../components/Seo';
import { useGiftOffer } from '../hooks/useGiftOffer';

//idea for optimization, might require <Suspense> wrapper
// const SingleDonation = React.lazy( () => import("@bgea/bgforms-templates/dist/templates/SingleDonation"));

function Page({ data }) {
  const giftOffer = useGiftOffer();
  let meta = {
    title: 'Billy Graham Evangelistic Association | Giving',
    description:
      'We rely on the prayers and gifts of friends like you. Your support is being used by God to transform lives around the world.',
    socialTitle:
      'Make an online Gift to the Billy Graham Evangelistic Association',
  };
  let styles = {};
  const projects = data?.projects?.nodes
    .filter((item) => item?.frontmatter?.image)
    .map((item) => ({
      ...item?.frontmatter,
      projectId: item?.frontmatter?.projectCode,
    }));
  projects.sort((a, b) => a?.order - b?.order);
  return (
    <Layout
      meta={meta}
      styles={styles}
      header={
        <HeaderPage
          title="Will you help reach hurting people around the world with the hope of Jesus Christ?"
          subtitle="Your gift today will share the Good News with others and point them to the love of our Heavenly Father."
          imageWidth={4}
          className="is-hidden-mobile"
        >
          <StaticImage src={'../images/header.jpg'} alt="Prayer Counseling" />
        </HeaderPage>
      }
    >
      <Ui.Section>
        <Ui.Container>
          <Ui.Columns>
            <Ui.Columns.Column>
              <p className="title">Give to Where Most Needed</p>
            </Ui.Columns.Column>
            <Ui.Columns.Column>
              <p>
                I want to change someone’s life for eternity with the hope of
                Jesus Christ.
              </p>
            </Ui.Columns.Column>
          </Ui.Columns>
        </Ui.Container>
      </Ui.Section>
      <Ui.Section className="py-0">
        <Ui.Container>
          <Ui.Columns centered={true}>
            <Ui.Columns.Column size={'two-thirds'}>
              <div id="singleDonation" className="pb-6">
                <SingleDonation
                  enableMonthlyModal={true}
                  giftOffer={giftOffer}
                  donationOptions={[
                    {
                      value: 'Where Most Needed',
                      id: 200001,
                    },
                  ]}
                  projectId={200001}
                  projectTitle={'Where Most Needed'}
                  showFreeGiftOffer={true}
                  esubCodes={['BGEA', 'PLTR']}
                  showUpgradeGift={true}
                  disablePaypalGiftOffer
                  defaultDebitDay={'5th'}
                  deliveryStatement={
                    '*While supplies last; please allow *4-6 weeks for delivery; U.S. residents only'
                  }
                />
              </div>
              <hr className="mb-6 my-3" />
              <div className="has-text-centered mb-6">
                <Ui.Button
                  renderAs="a"
                  size="medium"
                  color="primary"
                  className="is-outlined is-fullwidth"
                  href="/giving-areas"
                >
                  Or give to specific ministry areas.
                  <Ui.Icon className="ml-3">
                    <ChevronRightIcon />
                  </Ui.Icon>
                </Ui.Button>
              </div>
            </Ui.Columns.Column>
          </Ui.Columns>
        </Ui.Container>
      </Ui.Section>
    </Layout>
  );
}

Page.propTypes = {
  data: PropTypes.shape({}),
};

export const pageQuery = graphql`
  query {
    projects: allMdx {
      nodes {
        body
        fields {
          slug
        }
        frontmatter {
          title
          slug

          description
          projectCode
          order
        }
      }
    }
  }
`;

export default Page;

export const Head = ({ location, params, data, pageContext }) => {
  return (
    <Seo
      title="Billy Graham Evangelistic Association | Giving"
      description="Your gift today will make a difference in someone’s life for eternity."
      pathname={location.pathname}
    />
  );
};
